@import "../mixins";
@import "../variables";

  .tab {
    display: inline-block;
    padding: 6px 7px;
    background-color: #000000;
    color: black;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid transparent;
    border-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 13px;
    transition: background-color 0.3s;
    margin-top: 11px;
    font-size: 14px;
  }
  
  .tab:hover {
    background-color: #bebdbd;
  }
  
  .tab.active {
    border-color: white;
    border-bottom-color: black;
    border-width: medium;
    background: white;
    margin: 5px;
  }

  .breadcrumb {
    float: left;
    margin-top: 58px;
    margin-left: 8px;
    color: black;
  }

  .breadcrumb:hover {
    cursor: pointer;
    color: #bebdbd;
  }

  .logout {
    float: right;
    margin-top: 10px;
    margin-right: 8px;
    color: #eceefa;
    font-weight: normal;
    font-size: 14px;
  }

  .logout:hover {
    cursor: pointer;
    color: #bebdbd;
  }

  .tabs {
    //margin-left: calc( (100vw - 1600px) / 2)
    margin-left: 20px;
  }
  .auxtabframe {
    border: 0;
    //margin-left: calc( (100vw - 1600px) / 2);
    width: 0;
    height: 0;
    background-repeat: no-repeat, repeat;
    background-position: center;
    display:none;
    background-color: #eaebf0;
  }

  .tabframe {
    border: 0;
    //margin-left: calc( (100vw - 1600px) / 2);
    //width: calc( (100vw - 40px));
    height: 100vh;
    background-repeat: no-repeat, repeat;
    background-position: center;
    display:none;
    background-color: #eaebf0;
  }

  .icon {
    float: right;
    margin-top: 6px;
    margin-right: calc((101vw - 1600px) / 2);
    margin-right: 10px;
    cursor: pointer;
    height: 26px;
  }

  .menuicon {
    float: left;
    margin-top: 6px;
    margin-right: calc((101vw - 1600px) / 2);
    margin-left: 10px;
    cursor: pointer;
    height: 26px;
  }

  .pdficon {
    float: right;
    margin-top: 8px;
    cursor: pointer;
    height: 21px;
    margin-right: 18px;
  }

  .pwrpicon {
    float: right;
    margin-top: 10px;
    cursor: pointer;
    height: 32px;
    margin-right: 8px;
  }

  .gsicon {
    float: left;
    margin-right: 0;
    margin-left: 17px;
    margin-bottom: 10px;
    margin-top: 17px;
    cursor: pointer;
    height: 65px;
  }

  .dividericon {
    float: right;
    margin-top: 10px;
    margin-right: 21px;
    height: 31px;
  }

  .toolbar {
    width: 100%;
    fill: black;
    background-color: $line-theme;
    display: inline-block;
    margin-bottom: -3px;
    height: 40px;
  }

  .contentblock {
    //margin-left: 300px;
    //calc( (100vw - 300px));
    //display: block;
    margin-left: 300px;
    display: block;
    height: 100%;
    width: calc( (100vw - 300px));
    display: inline-block;
    position: fixed !important;
    z-index: 1;
  }


  .contentblockMobile {
    //margin-left: 300px;
    //calc( (100vw - 300px));
    //display: block;
    display: block;
    height: calc( (100vh ));
    width: 100vw;
    display: inline-block;
    position: fixed !important;
    z-index: 1;
  }

  .dashboardblock {
    width: 100%;
    fill: black;
    /* background-color: #26BBE3; */
    display: inline-block;
    /* margin-bottom: -3px; */
    height: 100%;
  }

  .sidebar {
    width: 300px;
    height: 100%;
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $slate-theme;
    display: block;
    position: fixed!important;
    z-index: 2;
    overflow: auto;
  }

  .sidebarMobile {
    width: 200px;
    height: 100%;
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $slate-theme;
    display: block;
    position: fixed!important;
    z-index: 2;
    overflow: auto;
    transition: left 0.5s ease, width 0.5s ease; /* Add transition */

  }

  .sidebarClosed {
    width: 0px;
    height: 100%;
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $slate-theme;
    display: block;
    position: fixed!important;
    transition: left 0.5s ease, width 0.5s ease; /* Add transition */
    z-index: 1;
    overflow: auto;
  }
  
  .sidebarlogo {
    margin-top: 10px;
    margin-left:52px;
    text-align: center;    
    align-items: center;
    width: 200px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }
  .sidebarlogoMobile {
    margin-top: 10px;
    margin-left:52px;
    text-align: center;    
    align-items: center;
    width: 100px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }

  .sidebarState {
    margin-top: 30px;
    margin-left:52px;
    text-align: center;    
    align-items: center;
    width: 200px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }

  .sidebarStateMobile {
    margin-top: 30px;
    margin-left:52px;
    text-align: center;    
    align-items: center;
    width: 100px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }

  .sideHeader {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid white;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }

  .sideState {
    height: 50px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-bottom:40px;
    font-size: 20px;
    font-weight: normal;
  }

  .sideButton {
    height: 50px;
    width: 100%;
    border-color: white;
    border-width: medium;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    font-weight: normal;
  }

  .sideButton.active {
    background-color: $sky-theme;
    color: white;
  }

  .sideButton.active:hover {
    color: white;
  }

  .sideButton:hover {
    cursor: pointer;
    color: $sky-theme;
  }

  .tabbar {
    width: 100%;
    fill: black
  }

  .landing {
    overflow: scroll;
    //border-bottom-color: $line-theme;
    /* border-bottom-color: black; */
    //border-width: medium;
    //border-bottom-style: solid;
    //display: inline-block;
    width: 100%;
  }

  

  @media all and (max-width: 1600px) {
    .tabframe {
        border: 0;
        background-color: #eaebf0;
        margin-left: 0;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat, repeat;
        background-position: center;
      }

      .tabs {
        margin-left: 0
      }

      .icon {
        margin-right: 10px;
      }
 }

 .loadingSpinnerContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eaebf0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  z-index: 9999;
  top:0
}

/* Styles for the spinning wheel */
.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #ffffff; /* Adjust the color as needed */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: fixed;
}

/* Animation for spinning */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}