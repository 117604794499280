@import "../mixins";
@import "../variables";

.loginTitle {
  @include display-flex();
  @include justify-content-center();
  font-size: 20px;
  margin-bottom: 70px;
  color: $line-theme;
}

.loginLogo {
  height: 100px;
  cursor: pointer;
}