* {
  font-weight: 700;
  color: white;
}

body {
  margin: 0;
  font-family: Tahoma, "Trebuchet MS", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //background: radial-gradient(circle at center, #353E57 , #313b55)
  background-image: url(../src/assets/main-background.jpg);
  background-size: 100vw 100vh
}

::-webkit-input-placeholder {
  text-align: left;
  line-height: normal;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: left;  
  line-height: normal;
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: left;  
  line-height: normal;
}

:-ms-input-placeholder {  
  text-align: left; 
  line-height: normal;
}